import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'

const frequency = [
  { value: 'monthly', label: 'Monthly', priceSuffix: '/month (billed annually)' },
  { value: 'annually', label: 'Annually', priceSuffix: '/year' },
]
const tiers = [
  {
    name: 'Open-Source',
    id: 'tier-freelancer',
    href: '#',
    price: 'Free',
    description: 'Setup, host and maintain Kollab on your own infrastructure.',
    features: [
      'Self-hosted open-source platform',
      'Access to community forums'
    ],
    mostPopular: false,
  },
  {
    name: 'Pro',
    id: 'tier-hobby',
    href: '#',
    price: '$2500',
    price_suffix: '/month (billed annually)',
    description: "Personal onboarding by our service team. The perfect plan for medium-sized businesses.",
    features: [
      'Custom domain & branding',
      'Kollab mobile app in AppStore & PlayStore with custom theming',
      '2 customer journey templates',
      'Up to 100 customers / month',
      'Unlimited automations',
      '24-hour support response time'
    ],
    featured: false,
    mostPopular: true,
  },
  {
    name: 'Enterprise',
    id: 'tier-enterprise',
    href: '#',
    description: 'Dedicated engineering support and infrastructure for your company.',
    price: 'Custom pricing',
    features: [
      'Custom domain & branding',
      'Own native mobile app in AppStore & PlayStore',
      'Unlimited customer journey templates',
      'Unlimited customers / month',
      'Unlimited automations',
      'Dedicated engineering representative',
      '1-hour support response time and SLA',
    ],
    featured: true,
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Pricing() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">Pricing</h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Pricing plans for teams of&nbsp;all&nbsp;sizes
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
          Choose an affordable plan that’s packed with the best features for engaging your audience, creating customer
          loyalty, and driving sales.
        </p>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
  {tiers.map((tier) => (
    <div
      key={tier.id}
      className={classNames(
        tier.mostPopular ? 'ring-2 ring-indigo-600' : 'ring-1 ring-gray-200',
        'rounded-3xl p-8 xl:p-10'
      )}
    >
      <div className="flex items-center justify-between gap-x-4">
        <h3
          id={tier.id}
          className={classNames(
            tier.mostPopular ? 'text-indigo-600' : 'text-gray-900',
            'text-lg font-semibold leading-8'
          )}
        >
          {tier.name}
        </h3>
        {tier.mostPopular ? (
          <p className="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600">
            Most popular
          </p>
        ) : null}
      </div>
      <p className="mt-4 text-sm leading-6 text-gray-600">{tier.description}</p>
      {tier.price ? (
        <p className="mt-6 flex items-baseline gap-x-1">
          <span className="text-4xl font-bold tracking-tight text-gray-900">{tier.price}</span>
          {tier.price_suffix && (
            <span className="text-sm font-semibold leading-6 text-gray-600">{tier.price_suffix}</span>
          )}
        </p>
      ) : (
        <p className="mt-6 text-4xl font-bold tracking-tight leading-6 text-gray-900">Custom</p> // Empty space for alignment
      )}
      <a
        href={tier.href}
        aria-describedby={tier.id}
        className={classNames(
          tier.mostPopular
            ? 'bg-indigo-600 text-white shadow-sm hover:bg-indigo-500'
            : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300',
          'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
        )}
      >
        Get started
      </a>
      <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10">
        {tier.features.map((feature) => (
          <li key={feature} className="flex gap-x-3">
            <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
            {feature}
          </li>
        ))}
      </ul>
    </div>
  ))}
</div>
      </div>
    </div>
  )
}
